/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    br: "br"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "the-hidden-side-effects-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-hidden-side-effects-of-hearing-loss",
    "aria-label": "the hidden side effects of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The hidden side effects of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We aim to delve into the concealed consequences of hearing loss, shedding light on aspects that extend beyond the auditory realm. We will explore how this often-overlooked condition can impact emotional well-being, cognitive function, and even the dynamics of personal relationships. As we unravel the layers of the hidden side effects, our goal is to raise awareness, fostering a deeper understanding of the profound implications hearing loss can have on an individual's overall quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cognitive-decline",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cognitive-decline",
    "aria-label": "cognitive decline permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cognitive Decline"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Studies show that hearing aids help keeping you sharp. In fact, a study documented hearing loss and cognitive decline among a group of volunteers over 25 years. What researchers found was revolutionary — people with hearing loss who didn’t use hearing aids had a much steeper cognitive decline and a higher risk of dementia. However, people with hearing loss who did use hearing aids experienced cognitive decline at a rate similar to people without hearing loss. Also, the risk of dementia was higher for those with hearing loss who didn’t use hearing aids. They had a much steeper cognitive decline and a higher risk of dementia. However, people with hearing loss who did use hearing aids experienced cognitive decline at a rate similar to people without hearing loss. Also, the risk of dementia was significantly reduced"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The researchers concluded that the key wasn’t simply the ability to hear better but, more importantly, how better hearing allowed them to stay involved in everyday life. By restoring the ability to better communicate, hearing aids can help improve social interactions, mood, and provide the opportunity to participate in brain-stimulating activities that can help slow cognitive decline."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "risk-to-mental-health",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#risk-to-mental-health",
    "aria-label": "risk to mental health permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Risk to Mental Health"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing and speech are vital to our ability to communicate with other people through language. Free-flowing communication allows us to build fulfilling relationships and enjoy a vibrant life experience; it’s an essential part of being human.", React.createElement(_components.br), "\n", "Losing your ability to hear can dramatically impact the way you interact with others and experience life. It can also put you at greater risk for developing mental health disorders such as anxiety and depression. In fact, hearing loss and depression in older adults go hand in hand. One study showed that older adults with deafness were 47% more likely to experience depression symptoms. For those who already have mental health issues, hearing loss can worsen the problem."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "physical-risk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#physical-risk",
    "aria-label": "physical risk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Physical Risk"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People who have a lot of trouble hearing may be almost twice as likely to experience an accidental injury as individuals with excellent or good hearing, a U.S. study suggests.", React.createElement(_components.br), "\n", "“Hearing is a special sense and as such one of its prime sensory functions is as a warning system for the body,” said senior study author Dr. Neil Bhattacharyya, a researcher at Harvard Medical School and Brigham and Women’s Hospital in Boston.\n“When people have hearing loss, they may be less likely to hear warning signs of, for example, a bicycle or motorcycle coming towards them,” Bhattacharyya said by email. “They may be less likely to hear a car horn or someone yelling at them to ‘duck’ if a baseball is headed their direction.”\nlesser-known fact that hearing loss can cause balance disorders. Our ears are involved in more than just hearing, and the presence of the semicircular canals in our ears can lead to balance problems in people suffering from hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "affected-relationships",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#affected-relationships",
    "aria-label": "affected relationships permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Affected relationships"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss does not occur in a vacuum. Studies show that untreated hearing loss can negatively impact our relationships with family and friends and particularly with those closest to us, such as our romantic partners.", React.createElement(_components.br), "\n", "\"Studies show that hearing loss produces feelings of frustration, embarrassment, and distress for the partner and for the relationship in general,\" said two researchers who conducted a qualitative study of couples where one partner had hearing loss.\nThe researchers found that \"both the hearing-impaired participants and their close partners bemoaned the loss of spontaneity and the difficulties of sharing small, unexpected incidents, observations and small talk in their everyday interactions.\""), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist",
    "aria-label": "contact a hearing specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you suspect that you or a loved one may be experiencing hearing loss, don't hesitate to take the next step towards a better quality of life. At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we're committed to guiding you through the entire journey of finding the perfect hearing aids tailored to your specific needs. Our team includes the top 2% of audiologists in the United States, ensuring you receive expert care and support. Reclaim the sounds of your life—reach out to hear.com today for a personalized and transformative hearing experience. Your path to better hearing starts here."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
